/*
 * SPDX-FileCopyrightText: 2024 Comcast
 *
 * SPDX-License-Identifier: LicenseRef-Comcast
 */

$color_1: #fff;
$color_2: white;
$color_3: unset;
$color_4: #000;
$color_5: #9aa2ab;
$background_color_1: #2f3246;
$background_color_2: #0e1126;
$background_color_3: transparent;
$background_color_4: #13cc13;
$background_color_5: #b60000;
$background_color_6: darkorange;
$background_color_7: yellow;
$background_color_8: #3561db;
$background_color_9: #d1d1d1;
$background_color_10: #edf2f5;
$border_color_1: transparent #43474A;