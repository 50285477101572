/*
 * SPDX-FileCopyrightText: 2024 Comcast
 *
 * SPDX-License-Identifier: LicenseRef-Comcast
 */

@import 'colors.scss';
@import '~@cxt-cee-chat/merc-pattern-lib/scss/settings/index';
@import '~@cxt-cee-chat/merc-pattern-lib/components/cards/cee-card.component';
@import "~@cxt-cee-chat/merc-pattern-lib/scss/settings/";

$circle-size: 8px;

.cee-agent-state {
  position: relative;

  select {
    .cee-theme-dark & {
      background: #000;
      option {
        appearance: none;
        background: $cool-gray-14;
        color: $white;
      }
    }
  }

  .cee-form-select {
    border: none;
    select {
      padding: 4px 12px;
      padding-left: $spacing-stack-32;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: $circle-size;
    height: $circle-size;
    border-radius: 100%;
  }

  &.green {
    &::before {
      background: themed("default", "colorSuccess");
    }
  }

  &.yellow {
    &::before {
      background: themed("default", "colorWarning");
    }
  }

  &.red {
    &::before {
      background: themed("default", "colorCritical");
    }
  }

  .cee-theme-dark & {
    .cee-form-select {
      border: none;
    }

    &.green {
      &::before {
        background: themed("dark", "colorSuccess");
      }
    }

    &.yellow {
      &::before {
        background: themed("dark", "colorWarning");
      }
    }

    &.red {
      &::before {
        background: themed("dark", "colorCritical");
      }
    }
  }
}

.cee-page-content {
	height: 100vh;
}

.cee-left-nav-menu li button {
	height: 50px;
}

.cee-card--conversation-meta .cee-btn {
	font-size: .75rem;
	border-radius: 3px;
	padding: 2px 4px;
}

/* Emoji area */
html {
	margin: 0;
	padding: 0;
  height: 100%;
  overflow: hidden;
}
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

merc-app {
	height: 100%;
	> .container-fluid {
		height: 100%;
		height: calc(100% - 57px);
		> .row {
			height: 100%;
		}
	}
}

// header
.merc-header {
	height: 50px;
}

.cee-sidebar,
.cee-chatutil,
.cee-chatheader {
	background: themed('default', 'cardBackground');

	.cee-theme-dark & {
		background: themed('dark', 'cardBackground');
	}
}

.merc-settings-tab-panel.cee-tab-panel.cee-modal {
  left: 48px;
  width: calc((100% - 48px) * .25);
  &.tab-active{
    width: calc(100% - 48px);
  }
}

.merc-manage-script-backdrop{
  .merc-manage-script-modal.cee-modal{
    width: calc((100% - 48px) * .75);
    &.active{
      left: calc(((100% - 48px) * .25) + 48px);
    }
  }
}



#tab-pan {
	background-color: #2a2a2a;
}
.hover-highlight {
	&:hover {
		background-color: #202020;
	}
}
.show {
	.active-highlight {
		background-color: #202020;
	}
}
.empty-filler-container {
	text-align: center;
	background-color: #2A2A2A;
	padding: 15px;
}

.hamburger.dropdown-toggle {
	&:after {
		margin-left: unset;
		vertical-align: unset;
		content: unset;
		border: unset;
		content: "\2807";
		font-size: 2rem;
		line-height: 1.3;
		padding-left: 8px;
	}
}
.hamburger {
	&:after {
		content: "\2807";
		font-size: 2rem;
		line-height: 1.3;
		padding-left: 8px;
	}
}
.bg-dark {
	background-color: #202020 !important;
}
.pointer {
	&:hover {
		cursor: pointer;
	}
}
.navbar.bg-dark {
	border-bottom: 1px solid #181818;
}
.dropdown-menu.bg-dark {
	border: 1px solid #181818;
	color: $color_1;
	.dropdown-item {
		&:hover {
			background-color: #2a2a2a;
			color: $color_2;
		}
	}
}
.form-control {
	background-color: $background_color_3;
	color: $color_3;
}
.crc {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 20px;
}
.crc.green {
	background-color: $background_color_4;
}
.crc.red {
	background-color: $background_color_5;
}
.crc.orange {
	background-color: $background_color_6;
}
.crc.yellow {
	background-color: $background_color_7;
}
.btn.btn-primary {
	background-color: $background_color_8;
	color: $color_1;
}
#chat-window {
	.message.left {
		.message-text {
			background-color: #43474A;
			color: $color_1;
			&::before {
				position: absolute;
				top: 15%;
				display: block;
				content: "";
				border-style: solid;
				left: -10px;
				border-color: $border_color_1;
				border-width: 10px 10px 10px 0;
				box-sizing: border-box;
			}
		}
		.message-tag {
			margin-left: 0px;
		}
	}
	.message.right {
		text-align: right;
		margin-right: 20px;
		.message-text {
			background-color: #202020;
			color: #fff;
			text-align: left;
			margin-right: 0;
			margin-left: auto;
			&::after {
				position: absolute;
				top: 15%;
				display: block;
				content: "";
				border-style: solid;
				left: 100%;
				border-color: transparent #202020;
				border-width: 10px 0px 10px 10px;
				box-sizing: border-box;
			}
		}
	}
	.message-text {
		background-color: $background_color_9;
		padding: 10px;
		padding-left: 15px;
		color: $color_4;
		position: relative;
		overflow-wrap: break-word;
		word-wrap: break-word;
		margin-left: 5px;
		margin-top: 10px;
		box-shadow: 5px 5px 2px #1d1f2b;
	}
	.message.system {
		.message-text {
			background: unset;
			color: $color_1;
			box-shadow: unset;
			width: 100%;
			text-align: center;
			padding-bottom: 0px;
		}
		.message-tag {
			background: unset;
			color: $color_1;
			box-shadow: unset;
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
		}
	}
}
.modal-content {
	background-color: #202020;
	.close {
		color: $color_1;
		text-shadow: none;
	}
}
.modal-footer {
	border: none;
}
.modal-header {
	border: none;
}

.vertical-center {
	min-height: 100%; /* Fallback for vh unit */
	min-height: 100vh; /* You might also want to use
                        'height' property instead.

                        Note that for percentage values of
                        'height' or 'min-height' properties,
                        the 'height' of the parent element
                        should be specified explicitly.

                        In this case the parent of '.vertical-center'
                        is the <body> element */

	/* Make it a flex container */
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	/* Align the bootstrap's container vertically */
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	/* In legacy web browsers such as Firefox 9
     we need to specify the width of the flex container */
	width: 100%;

	/* Also 'margin: 0 auto' doesn't have any effect on flex items in such web browsers
     hence the bootstrap's container won't be aligned to the center anymore.

     Therefore, we should use the following declarations to get it centered again */
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.logo-lg {
	background-image: url(/assets/img/mercury-logo-lg.png);
	width: 419px;
	height: 360px;
}

.ani-fade {
	-webkit-animation: fadeOut 1s linear infinite;
	animation: fadeOut 3s linear infinite;
	animation-fill-mode: forwards;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .5;
	}
	100% {
		opactity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .3;
	}
	100% {
		opactity: 1;
	}
}

[contenteditable=true]:empty:not(:focus):before {
	content: attr(placeholder);
}
